import { useQuery } from 'react-query';
import engineClient from '../engine-client';

export const USER_QUERIES_KEYS = {
  USER_QUESTS: ['userQuests'],
  CODESPACE_STATUS: 'myCodespaceStatus',
  MY_AUTHORED_QUESTS: 'myAuthoredQuests',
  USER_PROFILE: userId => ['userProfile', userId],
};

export function useChallengesWithUserStatusQuery(userId, userAchievements) {
  return useQuery(['challenges', userId, userAchievements], async () => {
    return await engineClient.getChallengesWithUserStatus();
  });
}

export function useUserQuestCertificateQuery(questPrimaryId) {
  return useQuery(['userQuestCertificate', questPrimaryId], async () => {
    const data = await engineClient.getOrCreateUserQuestCertificate(
      questPrimaryId
    );
    return data;
  });
}

export function useUserAchievementsQuery(userId) {
  return useQuery(['userAchievements', userId], async () => {
    return await engineClient.getUserAchievements();
  });
}

export function useUserGroupSummaryQuery(userId, companyName, groupId) {
  return useQuery(['userGroupSummary', userId, groupId], async () => {
    const data = await engineClient.getUserGroupSummary(companyName, groupId);
    return data;
  });
}

export function useUserQuestsQuery(withCertificates = false) {
  return useQuery(USER_QUERIES_KEYS.USER_QUESTS, async () => {
    const data = await engineClient.getUserQuests(withCertificates);
    return data;
  });
}

export function useUserGroups(userId) {
  return useQuery(['userGroups', userId], async () => {
    const data = await engineClient.getUserGroups();
    return data;
  });
}

export function useGithubConnect(code) {
  return useQuery(['githubConnect', code], async () => {
    const data = await engineClient.connectGithubApp(code);
    return data;
  });
}

export function useWixConnect(code) {
  return useQuery(['wixConnect', code], async () => {
    const data = await engineClient.connectWixApp(code);
    return data;
  });
}

export function useWixUserConnect(wixUserId) {
  return useQuery(['wixUserConnect', wixUserId], async () => {
    const data = await engineClient.connectWixUser(wixUserId);
    return data;
  });
}

export function useMyCodespace() {
  return useQuery(['myCodespace'], async () => {
    const data = await engineClient.myCodespace();
    return data;
  });
}

export function useMyCodespaceStatus() {
  return useQuery(['myCodespaceStatus'], async () => {
    const data = await engineClient.myCodespaceStatus();
    return data;
  });
}

export function useMyAuthoredQuests() {
  return useQuery(['myAuthoredQuests'], async () => {
    const data = await engineClient.getUserAuthoredQuests();
    return data;
  });
}
