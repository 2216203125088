import { useCallback, useContext, useEffect } from 'react';
import { MODAL_TYPE } from '../modals/modalType';
import {
  APP_ACTIONS,
  AppContext,
  MODAL_MESSAGE_STATUS_ACTIONS,
} from '../appContext';
import { FETCH_ACTIONS } from '../Fetcher';

export const notificationsHandler = (dispatch, notification) => {
  if (notification.seenAt) {
    ackNotification(dispatch, notification);
  } else {
    dispatchNotification(dispatch, notification);
  }
};

export const ackNotification = (dispatch, notification) => {
  dispatch({
    type: MODAL_MESSAGE_STATUS_ACTIONS.CLEAR_SPECIFIC_MODAL,
    payload: {
      id: notification.id,
    },
  });
};

const dispatchNotification = async (dispatch, notification) => {
  dispatch({
    type: MODAL_MESSAGE_STATUS_ACTIONS.MODAL_MESSAGE,
    payload: {
      category: MODAL_TYPE.NOTIFICATION,
      ...notification,
    },
  });
};

const dispatchNotifications = async (dispatch, notifications) => {
  dispatch({
    type: MODAL_MESSAGE_STATUS_ACTIONS.MODAL_MESSAGES,
    payload: {
      notifications: await Promise.all(
        notifications.map(async notification => {
          return {
            category: MODAL_TYPE.NOTIFICATION,
            ...notification,
          };
        })
      ),
    },
  });
};
const sortByPriority = notifications => {
  notifications.sort((x, y) => y.priority - x.priority);
};

const NotificationsManager = () => {
  const {
    state: {
      fetch: { [FETCH_ACTIONS.FETCH_PENDING_NOTIFICATIONS]: notifications },
    },
    dispatch,
  } = useContext(AppContext);

  const fetchNotifications = useCallback(() => {
    dispatch({
      type: APP_ACTIONS.FETCH,
      payload: { action: FETCH_ACTIONS.FETCH_PENDING_NOTIFICATIONS },
    });
  }, [dispatch]);
  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    const data = notifications?.data?.notifications;
    if (data) {
      sortByPriority(data);
      setTimeout(() => {
        dispatchNotifications(dispatch, data);
      }, 1000);
    }
  }, [dispatch, notifications]);

  return null;
};

export default NotificationsManager;
