export const FEATURE_FLAGS = {
  PUBLISH_PERMISSION: 'PUBLISH_PERMISSION',
  HEBREW: 'HEBREW',
  DOCUMENTATION_QUEST: 'DOCUMENTATION_QUEST',
  CHECK_USER_ANSWER: 'CHECK_USER_ANSWER',
  QUEST_FEEDBACK: 'QUEST_FEEDBACK',
  QUEST_WITH_REPO: 'QUEST_WITH_REPO',
};

export const ANONYMOUS_FEATURE_FLAGS = {};
