import React, { Suspense } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import '@fontsource/readex-pro/700.css';
import '@fontsource/readex-pro/500.css';
import '@fontsource/readex-pro/400.css';
import '@fontsource/readex-pro/300.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { rollbarConfig } from './rollbar';
import ServerEvents from './ServerEvents';
import { AppProvider } from './appContext';
import Fetcher from './Fetcher';
import AuthSessionVerifier from './AuthSessionVerifier';
import DataLoader from './DataLoader';
import RollbarPersonContext from './RollbarPersonContext';
import SocketInitializer from './SockerInitializer';
import WilcoSplitFactory from './featureFlags/splitio/factory';
import QueryProvider from './queryProvider/QueryProvider';
import { lazyWithRetry } from './utils/react.utils';
import I18n from './I18n';
import WilcoEngineHealthCheck from './WilcoEngineHealthCheck';
const WilcoApp = lazyWithRetry(() => import('./WilcoApp'));
const AdminApp = lazyWithRetry(() => import('./admin/App'));

function CurrentApp() {
  switch (window.location.origin) {
    case process.env.REACT_APP_ADMIN_APP_URL:
      return <AdminApp />;
    default:
      return <WilcoApp />;
  }
}

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <AppProvider>
          <Router>
            <WilcoSplitFactory>
              <WilcoEngineHealthCheck>
                <AuthSessionVerifier>
                  <RollbarPersonContext>
                    <QueryProvider>
                      <SocketInitializer />
                      <ServerEvents />
                      <Fetcher />
                      <DataLoader />
                      <I18n />
                      <Suspense fallback={null}>
                        <CurrentApp />
                      </Suspense>
                    </QueryProvider>
                  </RollbarPersonContext>
                </AuthSessionVerifier>
              </WilcoEngineHealthCheck>
            </WilcoSplitFactory>
          </Router>
        </AppProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
