import { useEffect, useContext } from 'react';
import { useRollbar } from '@rollbar/react';
import { AppContext } from './appContext';

function RollbarPersonContext({ children }) {
  const {
    state: { user },
  } = useContext(AppContext);

  const rollbar = useRollbar();

  useEffect(() => {
    if (user?.id) {
      rollbar.configure({
        payload: {
          person: {
            id: user.id,
          },
        },
      });
    }
  }, [user?.id, rollbar]);

  return children;
}

export default RollbarPersonContext;
