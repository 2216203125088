import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import { ANONYMOUS_FEATURE_FLAGS } from '../consts';
import { v4 as uuid } from 'uuid';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import analytics from './../../analytics/analytics';
import { ANALYTIC_EVENT } from '../../analytics/events';

const CONFIG = {
  DISABLED: process.env.REACT_APP_SPLITIO_DISABLED,
  API_KEY: process.env.REACT_APP_SPLITIO_KEY,
  LOCALHOST_FILE: process.env.REACT_APP_SPLITIO_LOCALHOSTS_FILE,
  DEBUG_MODE: process.env.REACT_APP_SPLITIO_DEBUG,
  ANONYMOUS_ID_COOKIE: 'feature_flag_anonymous_id',
};

const config = userKey => {
  return {
    core: {
      authorizationKey: CONFIG.API_KEY || 'localhost',
      key: userKey,
    },
    impressionListener: {
      logImpression: logImpression,
    },
    sync: {
      splitFilters: [
        {
          type: 'byName',
          values: Object.values(ANONYMOUS_FEATURE_FLAGS),
        },
      ],
    },
    debug: CONFIG.DEBUG_MODE === 'true',
  };
};

function logImpression(data) {
  const impression = data.impression;
  analytics.track(ANALYTIC_EVENT.FEATURE_FLAG_TREATMENT, {
    treatment: {
      user: impression.keyName,
      feature: impression.feature,
      treatment: impression.treatment,
    },
  });
}

const WilcoSplitFactory = ({ children }) => {
  const [cookies, setCookie] = useCookies();
  const [id, setId] = useState(cookies[CONFIG.ANONYMOUS_ID_COOKIE]);

  useEffect(() => {
    if (!id) {
      const anonymousId = uuid();
      setCookie(CONFIG.ANONYMOUS_ID_COOKIE, anonymousId);
      setId(anonymousId);
    }
  }, [id, setCookie]);

  return id ? (
    <SplitFactory factory={SplitSdk(config(id))}>{children}</SplitFactory>
  ) : null;
};

export default WilcoSplitFactory;
