const config = {
  APP_ID: process.env.REACT_APP_COMET_API_ID,
  APP_REGION: process.env.REACT_APP_COMET_APP_REGION || 'us',
  INITIAL_CONVERSATION_MESSAGES_LIMIT: 50,
  INITIAL_CHANNELS_LIMIT: 10,
  INITIAL_FRIENDS_LIMIT: 10,
  MAX_SECONDS_TO_COMBINE: 300,
};

export { config };
