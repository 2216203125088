import { useContext, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import i18n from 'i18next';
import en from './locales/en';
import he from './locales/he';
import { AppContext } from './appContext';
import { hasFeatureFlag } from './featureFlags';
import { FEATURE_FLAGS } from './featureFlags/consts';

const resources = {
  en: {
    translation: en,
  },
  he: {
    translation: he,
  },
};

i18n.use(initReactI18next).use(i18nextPlugin).init({
  resources,
  fallbackLng: 'en',
});

i18n.services.formatter?.add('possessive', value => {
  return value.slice(-1) === 's' ? `${value}'` : `${value}'s`;
});

const I18n = () => {
  const {
    state: { user },
  } = useContext(AppContext);

  const hebrew = hasFeatureFlag(FEATURE_FLAGS.HEBREW, user);

  useEffect(() => {
    if (hebrew) {
      i18n.changeLanguage('he');
    } else {
      i18n.changeLanguage('en');
    }
  }, [hebrew]);

  return null;
};

export default I18n;
