import auth0 from 'auth0-js';
import * as CometClient from '../chat/CometClient';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const audience = `${process.env.REACT_APP_AUTH0_AUDIENCE}`;

if (!clientId || !domain || !audience) {
  throw new Error(
    '`REACT_APP_AUTH0_DOMAIN`, `REACT_APP_AUTH0_CLIENT_ID` or `REACT_APP_AUTH0_AUDIENCE`' +
      ' env var are not set, please add them as env var (.env file or as env vars) from 1password (look for `wilco-frontend .env`)'
  );
}

export const AUTH0_ERRORS = {
  INVALID_PASSWORD: 'invalid_password',
};

class AuthService {
  constructor(clientId, domain) {
    this.auth0 = new auth0.WebAuth({
      clientID: clientId,
      domain,
      responseType: 'token id_token',
      redirectUri: `${window.location.origin}/callback`,
      audience,
    });
    this.login = this.login.bind(this);
    this.signupWithEmailAndPassword =
      this.signupWithEmailAndPassword.bind(this);

    this._lastAuthResult = null;
  }

  async signupWithEmailAndPassword({
    email,
    password,
    company,
    referrer,
    guestUserId,
    skipEmailVerification = false,
    appState = {},
  }) {
    return new Promise((resolve, reject) => {
      let user_metadata = {
        company: company,
        referrer: referrer,
        guestUserId: guestUserId,
        skip_email_verification: skipEmailVerification.toString(),
        redirect: appState?.redirect,
      };

      this.auth0.redirect.signupAndLogin(
        {
          connection: 'Username-Password-Authentication',
          email,
          password,
          user_metadata,
          appState,
        },
        err => {
          if (err) {
            return reject(err);
          }
          resolve();
        }
      );
    });
  }

  loginEmailPassword(email, password, appState) {
    this.auth0.login({
      email,
      password,
      appState,
    });
  }

  login(connection, appState) {
    const options = {
      connection,
      appState,
    };

    this.auth0.authorize(options);
  }

  silentLogin(company = null, externalUserId = null, redirect = null) {
    this.auth0.authorize({
      appState: {
        company,
        externalUserId,
        redirect,
      },
    });
  }

  handleAsyncAuth0Response(err, authResult, resolve, reject) {
    this._lastAuthResult = null;

    if (err) {
      return reject(err);
    }

    if (!authResult) {
      throw new Error('No auth results');
    }

    if (authResult.error) {
      throw new Error('Error: ' + authResult.error);
    }

    if (!authResult.accessToken || !authResult.idToken) {
      throw new Error('Error: missing access token or id token');
    }

    this._lastAuthResult = authResult;
    resolve(authResult);
  }

  async verifyLoginSessionActive() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        this.handleAsyncAuth0Response(err, authResult, resolve, reject);
      });
    });
  }

  async parseHash(hash) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({ hash }, (err, authResult) => {
        this.handleAsyncAuth0Response(err, authResult, resolve, reject);
      });
    });
  }

  async parseSession(hash) {
    const authResult = await this.parseHash(hash);

    const profile = {
      company: authResult.appState?.company,
      referrer: authResult.appState?.referrer,
      guestUserId: authResult.appState?.guestUserId,
      externalUserId: authResult.appState?.externalUserId,
      isCompanyAdmin: authResult.appState?.isCompanyAdmin,
      isGuest: authResult.appState?.isGuest,
      ...authResult.idTokenPayload,
    };

    return {
      profile,
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      redirect: authResult.appState?.redirect,
    };
  }

  getAccessToken() {
    return this._lastAuthResult?.accessToken;
  }

  logout(returnTo) {
    CometClient.logout();
    this.auth0.logout({
      returnTo: returnTo || `${window.location.origin}/signup`,
    });
  }
}

const auth = new AuthService(clientId, domain);
export { auth };
