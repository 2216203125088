import { Box, Icon, Link, Text } from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import React from 'react';

const HealthcheckErrorPage = ({ title, description, healthcheckUrl }) => {
  return (
    <Box
      bg="#303138"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      flexDirection="column"
      textAlign="center"
    >
      <Icon as={MdError} boxSize={40} color="red.500" />
      <Text fontSize={40} fontWeight="bold" mb={2}>
        {title}
      </Text>
      <Text fontSize={20} opacity={0.8}>
        {description}
      </Text>
      <Link
        isExternal={true}
        href={healthcheckUrl}
        color="#50B088"
        cursor="pointer"
        mt={4}
        fontSize={20}
      >
        Check health
      </Link>
    </Box>
  );
};

export default HealthcheckErrorPage;
