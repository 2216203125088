import Rollbar from 'rollbar';

export const rollbarConfig = {
  enabled: process.env.REACT_APP_ROLLBAR_TOKEN ? true : false,
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  payload: {
    environment: process.env.REACT_APP_NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_SHA,
      },
    },
  },
};

export const rollbar = new Rollbar(rollbarConfig);
