import React, { useEffect, useState, useCallback } from 'react';
import engineClient from './wilco-engine/engine-client';
import HealthcheckErrorPage from './pages/HealthcheckErrorPage';

const WilcoEngineHealthCheck = ({ children }) => {
  const [isEngineHealthy, setIsEngineHealthy] = useState(true);
  const checkEngineHealth = useCallback(async () => {
    try {
      const response = await engineClient.healthcheck();
      if (response !== 'OK') {
        throw new Error('Health check failed');
      }
      setIsEngineHealthy(true);
    } catch {
      setIsEngineHealthy(false);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'local') {
      checkEngineHealth();
    }
  }, [checkEngineHealth]);

  return isEngineHealthy ? (
    children
  ) : (
    <HealthcheckErrorPage
      title="Could not connect to Wilco Engine"
      description={`Please check the engine is running at ${process.env.REACT_APP_ENGINE_URL}`}
      healthcheckUrl={`${process.env.REACT_APP_ENGINE_URL}/api/v1/health`}
    />
  );
};

export default WilcoEngineHealthCheck;
