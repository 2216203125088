import { CometChat } from '@cometchat-pro/chat';

const LOGIN_LISTENER_NAME = 'login_listener';
const CONNECTION_LISTENER_NAME = 'connection_listener';
const MESSAGE_LISTENER_NAME = 'message_listener';

interface ConnectionListener {
  onConnected: () => void;
  onDisconnected: () => void;
}

interface MessageListener {
  onTextMessageReceived: (message: CometChat.TextMessage) => void;
  onCustomMessageReceived: (message: CometChat.CustomMessage) => void;
}

class CometListeners {
  private connectionListener: ConnectionListener | undefined = undefined;
  private messageListener: MessageListener | undefined = undefined;

  constructor() {
    this.initLoginListener();
  }

  setListeners({
    connectionListener,
    messageListener,
  }: {
    connectionListener: ConnectionListener;
    messageListener: MessageListener;
  }) {
    this.connectionListener = connectionListener;
    this.messageListener = messageListener;
  }

  async initLoginListener() {
    CometChat.addLoginListener(
      LOGIN_LISTENER_NAME,
      new CometChat.LoginListener({
        loginSuccess: () => {
          this.resetListeners();
        },
        loginFailure: () => {
          this.removeListeners();
        },
      })
    );

    if (await CometChat.getLoggedinUser()) {
      this.resetListeners();
    }
  }

  addConnectionListener() {
    CometChat.addConnectionListener(
      CONNECTION_LISTENER_NAME,
      new CometChat.ConnectionListener({
        onConnected: () => {
          this.connectionListener?.onConnected();
        },
        onDisconnected: () => {
          this.connectionListener?.onDisconnected();
        },
      })
    );
  }

  private addMessageListener() {
    CometChat.addMessageListener(
      MESSAGE_LISTENER_NAME,
      new CometChat.MessageListener({
        onTextMessageReceived: async (message: CometChat.TextMessage) => {
          this.messageListener?.onTextMessageReceived(message);
        },
        onCustomMessageReceived: async (message: CometChat.CustomMessage) => {
          this.messageListener?.onCustomMessageReceived(message);
        },
      })
    );
  }

  private removeListeners() {
    CometChat.removeConnectionListener(CONNECTION_LISTENER_NAME);
    CometChat.removeMessageListener(MESSAGE_LISTENER_NAME);
  }

  private resetListeners() {
    this.removeListeners();
    this.addConnectionListener();
    this.addMessageListener();
  }
}

export default CometListeners;
