const envHasFeatureFlag = name => {
  return process.env[`REACT_APP_FF_${name.toUpperCase()}`] === 'true';
};

const queryParamHasFeatureFlag = name => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name) === 'true';
};

const userHasFeatureFlag = (featureFlag, user) => {
  return user?.featureFlags
    ?.map(f => f.toLowerCase())
    .includes(featureFlag.toLowerCase());
};

export const hasFeatureFlag = (featureFlag, user) => {
  return (
    userHasFeatureFlag(featureFlag, user) ||
    queryParamHasFeatureFlag(featureFlag) ||
    envHasFeatureFlag(featureFlag)
  );
};
