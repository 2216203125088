import CryptoJS from 'crypto-js';
import { isMobile } from 'react-device-detect';
import engineClient from '../wilco-engine/engine-client';

class Analytics {
  #sendBeaconTimer = null;
  #beaconProperties = null;

  context() {
    return {
      agent: window.navigator.userAgent,
      isMobile: !!isMobile,
    };
  }

  async sendBeacon(properties = {}) {
    if (this.#sendBeaconTimer) {
      clearTimeout(this.#sendBeaconTimer);
    }

    this.#beaconProperties = properties;
    this.#sendBeaconTimer = setTimeout(async () => {
      const beaconData = {
        source: 'frontend',
        context: {
          ...this.context(),
          ...this.#beaconProperties,
          pathname: window.location?.pathname,
        },
      };
      await engineClient.sendUserBeacon(beaconData);
    }, 5000);
  }

  async identify(user) {
    if (!user) return;

    const userData = {
      id: user.id,
      userId: user.id,
      email: user.email,
      firstName: user.nickname,
      nickname: user.nickname,
      feature_flags: user.featureFlags,
    };
    if (process.env.REACT_APP_INTERCOM_SECRET) {
      const secret = CryptoJS.HmacSHA256(
        user.id,
        process.env.REACT_APP_INTERCOM_SECRET
      );
      window.analytics.identify(user.id, userData, {
        integrations: {
          Intercom: {
            user_hash: secret.toString(CryptoJS.enc.Hex),
          },
        },
      });
    }
    await this.sendBeacon(userData);
  }

  async page(properties = {}) {
    window.analytics.page({
      ...this.context(),
      ...properties,
    });
  }

  track(
    event,
    properties = {},
    options = {},
    callback = () => {
      /* Intentionally empty */
    }
  ) {
    const eventProperties = {
      ...properties,
      user_initiated: true,
    };
    window.analytics.track(
      event,
      { ...this.context(), ...eventProperties },
      options,
      callback
    );
  }

  reset() {
    window.analytics.reset();
  }
}

const analytics = new Analytics();

export default analytics;
